import React from 'react';
import {Link} from 'react-router-dom';

const Cabecalho = props => {
    return (
        <div> 
            <nav className='navbar navbar-default navbar-fixed-top cabecalho'>
                <div className='container'>
                    <div className='navbar-header'>
                        <button type='button' className='navbar-toggle' data-toggle='collapse' data-target='#myNavbar'>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                            <span className='icon-bar'></span>
                        </button>
                        <div>
                            <img width='205' height='74' src="./images/Logo_Zico_B.png" alt="Zico Madeiras"/>
                        </div>                        
                    </div>
                    <div  className='collapse navbar-collapse' id='myNavbar'>
                        <ul className='nav navbar-nav navbar-right'>
                            <li><Link to='/'>INÍCIO</Link></li>
                            <li><Link to='/Empresa'>QUEM SOMOS</Link></li>
                            <li><Link to='/Portfolio'>PRODUTOS</Link></li>
                            <li><Link to='/Contatos'>FALE CONOSCO</Link></li>
                        </ul>
                    </div>
                </div>
            </nav>
        </div>

    )
}

export default Cabecalho