import React from 'react';

const ItemPortfolio = props => {
    return (
        <div className='col-sm-6'>
            <div className="card mb-3">
                <img src={props.conteudo.imagem} alt='' className="card-img-top img-portfolio" />
                <div className="card-body">
                    <h3 className="card-title">{props.conteudo.titulo}</h3>
                    <p className="card-text">{props.conteudo.descricao}</p>
                </div>
            </div>
        </div>
    )
}
export default ItemPortfolio