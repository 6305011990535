import React, {Component} from 'react'
import {auth} from './../firebase-config'
import {Redirect} from 'react-router-dom'

class Login extends Component{
    constructor (props) {
        super (props)

        this.state={
            estaAutenticado: false,
            estaLogando: false,
            erroAcesso: false
        }

        this.email = null
        this.senha = null

        this.autenticaUsuario =  this.autenticaUsuario.bind(this)
    }
    autenticaUsuario(){
        this.setState({estaLogando: true, erroAcesso: false})
        auth.signInWithEmailAndPassword(this.email.value, this.senha.value)
        .then(user=>{
            this.setState({estaAutenticado: true})
        })
        .catch(err=>{
            console.log("Erro: ",err)
            this.setState({estaAutenticado: false, estaLogando: false, erroAcesso: true})
        })
    }
    render(){
        if (this.state.estaAutenticado){
            return <Redirect to='/admin'/>
        }
        return(
            <div className="container" style={{padding: 100 + 'px', maxWidth: 500 +'px'}} >
                <h1>Login</h1>
                <div className="form-group">
                    <label htmlFor="exampleInputEmail1">Email</label>
                    <input type="email" name= "email" ref={ref => this.email = ref} className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" />
                    
                </div>
                <div className="form-group">
                    <label htmlFor="exampleInputPassword1">Senha</label>
                    <input type="password" name = "senha" ref={ref => this.senha = ref} className="form-control" id="exampleInputPassword1" />
                    {this.state.erroAcesso && <small id="emailHelp" className="form-text text-muted">Usuário e/ou senha inválido(s).</small>}
                </div>
                <button type="button" disabled={this.state.estaLogando}  className="btn btn-primary" onClick={this.autenticaUsuario}>Submit</button>
                
            </div>
        )
    }
}

export default Login