import React, {Component} from 'react';
import Iframe from 'react-iframe';

class Maps extends Component {
    render(){
        return (                
        <div >
            <Iframe frameBorder="0" width="100%" height="200" src="https://maps.google.com/maps?width=100%&amp;height=200&amp;hl=en&amp;coord=-26.222251,-49.417174&amp;q=Rua%20Jos%C3%A9%20Pruess%2C%20156%20-%20Cruzeiro%20-%20S%C3%A3o%20Bento%20do%20Sul%20%2F%20SC%20-%20CEP%2089286-240+(Zico%20Madeiras)&amp;ie=UTF8&amp;t=&amp;z=14&amp;iwloc=B&amp;output=embed">
            <a href="https://www.maps.ie/draw-radius-circle-map/">Google Maps Radius</a>
            </Iframe>
        </div>
        )
    }

    
}

export default Maps