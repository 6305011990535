import React from 'react';
import {Link} from 'react-router-dom';
import Maps from './Maps'

const Rodape = props => {
    return (
        
        <div className='row' >
            <div className='col-sm-12' style={{height: '200px'}}>
               <Maps></Maps>
            </div>
        
            <div className='col-sm-12'>
                <footer className='rodape'>
                    <div className='container'>
                        <div className='row'>                            
                                
                            <div className='col-sm-6'>
                                <p className='text-left'><b>Onde nos encontrar</b></p>
                                <p className='text-left'><span className='glyphicon glyphicon-map-marker'></span>&nbsp;&nbsp; Rua José Pruess, 156 - Cruzeiro - São Bento do Sul / SC - CEP 89286-240</p>
                                <p className='text-left'><span className='glyphicon glyphicon-phone'></span>&nbsp;&nbsp; (47) 3635-2293 | (47) 9 9995-6146</p>
                                <p className='text-left'><span className='glyphicon glyphicon-envelope'></span>&nbsp;&nbsp; madeiraszico@gmail.com</p>
                            </div>

                            <div className='col-sm-3'>
                                <p className='text-left'><b>Nossos produtos:</b></p>
                                <ul>
                                    <li>Madeiras Brutas</li>
                                    <li>Lambril</li>
                                    <li>Forros</li>
                                    <li>Assoalhos</li>
                                    <li>Eucalipto e Pinus</li>
                                </ul>                                
                            </div>

                            <div className='col-sm-3 text-center'>
                                <p><b>Formas de Pagamento</b></p>
                                <img width="50%" src="./images/iconespagamento.png" alt="Zico Madeiras"/>
                                <div style={{marginTop: 20 + 'px'}}>
                                    <Link className='btnrodape' to='/Contatos'>Orçamento</Link>
                                </div>
                            </div>
                        </div>                
                    </div>                
                </footer>
            </div>    
        </div>
    )
}

export default Rodape
