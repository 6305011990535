import React, {Component} from 'react';

import config from './../firebase-config'
import ItemPortfolio from './itemPortfolio';

class Portfolio extends Component {
    constructor(props) {
        super(props)

        //Gerenciar o estado
        this.state={
            portfolio:{
                titulo: '',
                descricao: ''
            }
        }

        config.syncState('dbPortfolio',{
            context: this,
            state: 'portfolio',
            asArray: false
        })
    }

    render(){
        return (
            <div>
                <div id='portfolio' className='container-fluid text-center bg-grey'>
                <h4 style={{color:'#425d23', paddingTop: 10+'px'}}><strong>A empresa Zico Madeiras, trabalha com a madeira de<br /> Eucalipto (eucalyptus grandis), serrado bruto, seca ao tempo.</strong></h4><br />
                
                    <div id='myCarousel' className='carousel slide text-center' data-ride='carousel' style={{marginTop: -80+'px'}}>
                        {/* Indicators */}
                        <ol className='carousel-indicators'>
                            <li data-target='#myCarousel' data-slide-to='0' className='active'></li>
                            <li data-target='#myCarousel' data-slide-to='1'></li>
                            <li data-target='#myCarousel' data-slide-to='2'></li>
                        </ol>
    
                        {/* Wrapper for slides */}
                        <div className='carousel-inner' role='listbox'>
                            <div className='item active'>
                                <h4>Os mesmos são comercializados em diversas bitolas (larguras e espessuras),<br /> e também com diversas classificações, se adequando ao uso especifico do cliente, <br />que vai desde o uso da madeira para partes internas, como o ramo dos estofados,<br /> até ao acabamento mais sofisticado.</h4>
                            </div>
                            <div className='item'>
                                <h4>Trabalhamos também com a madeira de eucalipto para uso<br /> da construção civil, sendo destinada para fabricação de<br /> assoalho, deck, forro e lambril divisória.</h4>
                            </div>
                            <div className='item'>
                                <h4>A matéria prima comercializada em nossa empresa<br /> tem origem de reflorestamentos, tendo assim sua origem<br /> comprovada sem agredir ao meio ambiente.</h4>
                            </div>
                        </div>
    
                        {/* Left and right controls */}
                        <a className='left carousel-control' href='#myCarousel' role='button' data-slide='prev'>
                            <span className='glyphicon glyphicon-chevron-left' aria-hidden='true'></span>
                            <span className='sr-only'>Previous</span>
                        </a>
                        <a className='right carousel-control' href='#myCarousel' role='button' data-slide='next'>
                            <span className='glyphicon glyphicon-chevron-right' aria-hidden='true'></span>
                            <span className='sr-only'>Next</span>
                        </a>
                    </div>
                
                    <h4>Confira nossos produtos</h4>
                    <div className='row text-center '>
                        {/*}Loop com as fotos*/}
                        {
                            Object.keys(this.state.portfolio)
                            .map(key =>{
                                return <ItemPortfolio key={key} conteudo={this.state.portfolio[key]} />
                            })
                        }

                    </div>
                    
    
                    
                </div>
            </div>
        )
    }

    
}

export default Portfolio