const Rebase = require('re-base')
const firebase = require('firebase')

const FirebaseConfig = {
  apiKey: "AIzaSyAxHaH_OuVdGMcpFU6hNyMa-GLIQSSaokg",
  authDomain: "dbzicomadeiras.firebaseapp.com",
  databaseURL: "https://dbzicomadeiras.firebaseio.com",
  projectId: "dbzicomadeiras",
  storageBucket: "dbzicomadeiras.appspot.com",
  messagingSenderId: "894668931952",
  appId: "1:894668931952:web:4787c2a8a5122d031345b5",
  measurementId: "G-W95VQFGGSE"


   
  };
  // Initialize Firebase
  
  
  const app = firebase.initializeApp(FirebaseConfig)
  const config = Rebase.createClass(app.database())
  
  export const Storage = app.storage()
  export const auth = app.auth()
  export const analytic= firebase.analytics();
  export default config