import React, {Component} from 'react'
import config, {Storage} from './../firebase-config'

class AdminPortfolio extends Component{
    constructor(props){
        super(props)

        this.state={
            estaGravando: false
        }

        this._gravaPortfolio= this._gravaPortfolio.bind(this)
    }
    _gravaPortfolio(e){
        const itemPortifolio={
            titulo: this.titulo.value,
            descricao: this.descricao.value,
            imagem: this.imagem
        }

        this.setState({estaGravando: true})
        //Como recebe uma lista de arquivos, pega somente o primeiro
        const arquivo = itemPortifolio.imagem.files[0]
        const {name} = arquivo
        
        const ref = Storage.ref(name)
        // Faz o put da imagem no storage
        ref.put(arquivo)
            .then(img =>{
                img.ref.getDownloadURL()
                .then(downloadURL => { //pega a URL de retorno
                    
                    //Instanciando o objeto para ser gravado no banco (precisa estar igual ao Banco)
                    const novoPortfolio ={
                        titulo: itemPortifolio.titulo,
                        descricao: itemPortifolio.descricao,
                        imagem:downloadURL
                    }
                    
                    //Persiste no banco as informações junto com a url do Storage da imagem
                    config.push('dbPortfolio', {
                        data: novoPortfolio
                    })
                    this.setState({estaGravando: false})
                })
            })           

        e.preventDefault()
    }

    render(){
        if(this.state.estaGravando){
            return(

                <div className="d-flex justify-content-center text-center">
                <p>Salvando os dados... <span className="glyphicon glyphicon-refresh"></span></p>  
                    <div className="spinner-border" role="status">
                          
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

               
            )
        }
        return(
           
            <div className='container padt50' style={{maxWidth: 800 + 'px'}} >
                <div className="row" >
                    <div className="col-12">
                    <h2>Cadastro de Produtos - Área Administrativo</h2>
                        
                        <form onSubmit={this._gravaPortfolio}>
                            <div className='form-group'>
                                <label className="textForm" htmlFor='titulo'>Título</label>
                                <input type='text' className='form-control' id='titulo' placeholder='Insira o título da imagem' ref={(ref=> this.titulo = ref)} />
                            </div>
                            <div className='form-group'>
                                <label className="textForm"  htmlFor='descricao'>Descrição</label>
                                <textarea className="form-control" id="descricao" rows="3" ref={(ref=> this.descricao = ref)}></textarea>
                            </div>
                            <div className="form-group">
                                <label className="textForm"  htmlFor="imagem">Escolha sua imagem</label>
                                <input type="file" name="imagem" className="form-control-file textForm" id="imagem" ref={(ref=> this.imagem = ref)} />
                            </div>
                            <button type='submit' className='btn btn-primary'>Salvar</button>
                        </form>
                    </div>

                </div>
                
            </div>
        
        )
    }
}

export default AdminPortfolio