import React from 'react';

const Empresa = props => {
    return (
    <div className='container-fluid text-center bg-grey '>
        <div className='container-fluid' style={{maxWidth: 1400 + 'px'}}>
            <div className='row'>
                <div className='col-sm-4' style={{marginTop: 15 + 'px'}}  >                             
                        <img src='./images/empresa2.jpg' alt='' className="img-thumbnail" />
                        <img src='./images/empresa.jpg' alt='' className="img-thumbnail" />
                    
                </div>
                <div className='col-sm-8'  >
                    <h2 style={{color:'#425d23'}}>Nossa História</h2><br />
                    <p style={{textAlign: "justify"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A empresa teve inicio no ano de 1998, oferecendo matéria prima para a indústria moveleira da região, e ao longo dos anos buscou evolução e renovação, para sempre entregar o melhor produto aos nossos clientes
                    Buscamos a cada dia aprimorar a qualidade no atendimento. Contamos com profissionais altamente capacitados, transporte próprio, estoque amplo e um rigoroso controle de qualidade.</p><br />
                    <p style={{textAlign: "justify"}} > &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;A Zico Madeiras preocupa-se em fornecer à todos os clientes produtos de altíssima qualidade, legalizados, certificados e com procedência comprovada.  Reiteramos nosso compromisso com a preservação do meio-ambiente, pois acreditamos que o futuro do nosso Planeta está atrelado às praticas comerciais responsáveis. Por esse motivo agregamos em nosso estoque somente itens certificados e controlados pelos órgãos competentes.</p>
                </div>
            </div>

        </div>
            
        
    </div>
    )
}

export default Empresa