import React, {Component} from 'react'
import {Redirect} from 'react-router-dom'
import axios from 'axios'

class Contatos extends Component{
    constructor (props) {
        super (props)

        this.state={
            emailEnviado: false,
            enviando: false,
            nome: '',
            email: '',
            assunto: '',
            mensagem: '',
        }
        this.enviaEmail =  this.enviaEmail.bind(this)
        this.handleInputChange = this.handleInputChange.bind(this);

    }
    handleInputChange(event) {
        const target = event.target;
        const value = target.value;
        const name = target.name;
        this.setState({
          [name]: value    });
      }
    enviaEmail(){
        var self = this
        self.setState({emailEnviado: false, enviando: true})
        
        const ObjEnvio = {
            name: self.state.nome,
            email: self.state.email,
            assunto: self.state.assunto,
            mensagem: self.state.mensagem
        }

        axios({
            method: 'post',
            url: 'https://us-central1-dbzicomadeiras.cloudfunctions.net/enviarEmail',
            data: ObjEnvio
          })
          .then(function (response) {
            alert("Mensagem enviada com sucesso!");
            self.setState({emailEnviado: true, enviando: false})
            
          })
          .catch(function (error) {
            alert("Erro ao enviar mensagem. Tente novamente!")
          });
        
    }
    render(){
        if(this.state.enviando){
            return(

                <div className="d-flex justify-content-center text-center" style={{margin: 300 + 'px'}}>
                    <p>Enviando Mensagem...</p>  
                    <div className="spinner-border" role="status">
                          
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>

               
            )
        }
        if (this.state.emailEnviado){
            return <Redirect to='/'/>
        }
        
        return(

           <div id='contact' className='container-fluid bg-grey' >
                 <h2  style={{color:'#425d23'}} className='text-center'>Contato / Orçamento</h2>
                 <div className='container'>
                     <div className='row' style={{marginTop: 30 + 'px',maxWidth: 1400 + 'px'}}>
                         <div className='col-sm-6'>
                             <div>
                                
                                 <p style={{textAlign: "justify"}} >&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;Receba seu orçamento direto da <strong><a href="http://www.zicomadeiras.com.br/">Zico Madeiras</a>
                                 </strong>&nbsp;sem compromisso. Preencha o formulário ao lado com todas as informações que você tem interesse em receber, como: quantidade de madeiras, tipo madeiramento, quais produtos ou serviços que entraremos em contato para esclarecer demais necessidades e&nbsp;enviaremos &nbsp;proposta&nbsp;via e-mail.</p>
                                
                                 <p style={{textAlign: "justify"}} >Se preferir, entre em contato conosco, será um prazer atendê-lo:</p>   
                             </div>             
                             <p><span className='glyphicon glyphicon-phone-alt'></span><strong> (47) 3635-2293</strong></p>
                             <p><span className='glyphicon glyphicon-phone'></span><strong> (47) 9 9616-4283 (WhatsApp)</strong></p>
                             <p><span className='glyphicon glyphicon-envelope'></span><strong> madeiraszico@gmail.com</strong></p>
                             <p><span className='glyphicon glyphicon-map-marker'></span> Rua José Pruess, 156 - Cruzeiro - São Bento do Sul / SC - CEP 89286-240</p>
                       
                         </div>
                         <form action="#" method="post">   
                            
                             <div className='col-sm-6'>
                                 <div className='row'>
                                     <div className='col-sm-6 form-group '>
                                     
                                        <input className='form-control' id='name' name='nome' ref={ref => this.nome = ref}  value={this.state.nome} onChange={this.handleInputChange} placeholder='Nome' type='text' required />
                                     </div>
                                     <div className='col-sm-6 form-group'>
                                         <input className='form-control' id='email' name='email' ref={ref => this.email = ref} value={this.state.email} onChange={this.handleInputChange} placeholder='E-mail' type='email' required />
                                     </div>
                                     <div className='col-sm-12 form-group'>
                                         <input className='form-control' id='assunto' name='assunto' ref={ref => this.assunto = ref} value={this.state.assunto} onChange={this.handleInputChange} placeholder='Assunto' type='text' required />
                                     </div>
                                     <div className='col-sm-12 form-group'>
                                     <textarea className='form-control' id='mensagem' name='mensagem' ref={ref => this.mensagem = ref} value={this.state.mensagem} onChange={this.handleInputChange} placeholder='Mensagem' rows='5'></textarea><br />
                                     </div>
                                 </div>
                               
                                 <div className='row'>
                                     <div className='col-sm-12 form-group'>
                                         <button className='btn btnformcontato pull-right' type='button' onClick={this.enviaEmail}>Enviar</button>
                                     </div>
                                 </div>
                             </div> 
                         </form>
                     </div>      

                 </div>
                
             </div>

        )
        
    }
}


export default Contatos