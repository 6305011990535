import React from 'react';

const Inicio = props => {
    return (
        <div className="div">
            <div id='main-slider' className='carousel slide ' data-ride='carousel' style={{marginTop: 73 + 'px'}}>
                <ol className='carousel-indicators'>
                    <li data-target='#main-slider' data-slide-to='0' className=''></li>
                    <li data-target='#main-slider' data-slide-to='1' className=''></li>
                    <li data-target='#main-slider' data-slide-to='2' className='active'></li>
                    <li data-target='#main-slider' data-slide-to='3' className=''></li>
                    <li data-target='#main-slider' data-slide-to='4' className=''></li>
                </ol> 
                {/*.carousel-indicators*/}

                <div className='carousel-inner' role='listbox'>
                    <div className='item'>
                        <img width='100%' height='600' src='./images/Carrossel_1.jpg' className='d-block img-fluid wp-post-image' alt='Sustentabilidade' />
                        <div className='carousel-caption d-none d-md-block'>
                            <h1 className='text-uppercase text-right text-shadow'><b>Madeiras brutas</b></h1>
                            <p className='text-uppercase text-right text-shadow'><b> e m &nbsp;&nbsp;d i v e r s a s &nbsp;&nbsp;l a r g u r a s &nbsp;&nbsp;e &nbsp;&nbsp;e s p e s s u r a s</b></p>
                        </div>
                    </div>
                    {/*carousel-item*/}
                    <div className='item active item-left'>
                        <img width='100%' height='600' src='./images/Carrossel_4.jpg' className='d-block img-fluid wp-post-image' alt='Madeiras de Qualidade' />
                        <div className='carousel-caption d-none d-md-block'>
                            <h1 className='text-uppercase text-right text-shadow'><b>Madeiras de Qualidade</b></h1>
                            <p className='text-uppercase text-right text-shadow'><b>a l i a d a s&nbsp;&nbsp; à&nbsp;&nbsp; u m&nbsp;&nbsp; ó t i m o&nbsp;&nbsp; p r e ç o</b></p>
                        </div>
                    </div>
                    {/*.carousel-item*/}
                    <div className='item carousel-item-next item-left'>
                        <img width='100%' height='600' src='./images/Carrossel_3.jpg' className='d-block img-fluid wp-post-image' alt='O MAIOR DISTRIBUIDOR' />
                        <div className='carousel-caption d-none d-md-block'>
                            <h1 className='text-uppercase text-right text-shadow'><b>Eucalipto &nbsp;e&nbsp; Pinus </b></h1>
                            <p className='text-uppercase text-right text-shadow'><b>Assoalhos &nbsp;-&nbsp; Lambril &nbsp;-&nbsp; Forros</b></p>
                        </div>
                    </div>
                        {/*.carousel-item*/}
                    <div className='item carousel-item-next item-right'>
                        <img width='100%' height='600' src='./images/Carrossel_2.jpg' className='d-block img-fluid wp-post-image' alt='O MAIOR DISTRIBUIDOR' />
                        <div className='carousel-caption d-none d-md-block'>
                            <h1 className='text-uppercase text-right text-shadow'><b>Madeiras para  </b></h1>
                            <p className='text-uppercase text-right text-shadow'><b>C O N S T R U Ç Ã O &nbsp;&nbsp;C I V I L </b></p>
                        </div>
                    </div>{/*.carousel-item*/}
                    <div className='item'>
                        <img width='100%' height='600' src='./images/Carrossel_5.jpg' className='d-block img-fluid wp-post-image' alt='Resistência' />
                        <div className='carousel-caption d-none d-md-block'>
                            <h1 className='text-uppercase text-right text-shadow'><b>Resistência</b></h1>
                            <p className='text-uppercase text-right text-shadow'><b>e&nbsp; q u a l i d a d e</b></p>
                        </div>
                    </div>
                    {/*.carousel-item*/}
                    </div> {/*.carouse-inner*/}

                    <a className='left carousel-control' href='#main-slider' role='button' data-slide='prev'>
                        <span className='glyphicon glyphicon-chevron-left text-shadow' aria-hidden='true'></span>
                        <span className='sr-only'>Previous</span>
                    </a>
                    <a className='right carousel-control' href='#main-slider' role='button' data-slide='next'>
                        <span className='glyphicon glyphicon-chevron-right text-shadow' aria-hidden='true'></span>
                        <span className='sr-only'>Next</span>
                    </a>
            </div>
        <div className='container-fluid text-center bg-grey conteudo'>
           <div >
                <div className='col-sm-6'>                
                    <img src='./images/inicio.jpg' alt='' className="img-thumbnail-Inicio" />
                </div>
                <div className="d-flex justify-content-center text-center">
                    <div  role="status">
                        <img src='./images/Logo_Zico.png' alt='' className="img-thumbnail-Logo" />
                    <span className="sr-only">Loading...</span>
                    </div>
                </div>
                <div className='col-sm-6 '>
                    <div className="row">
                        <div className='col-sm-12 text-center'>
                            <p className='texto-inicio' style={{marginTop: 20 + 'px'}}><strong>Comércio de madeiras, brutas e beneficiadas em geral. Especializada em madeira de eucalipto.</strong></p>
                        </div>
                        <div className='col-sm-12 text-justify'>
                            <p className='texto-inicio'>&nbsp;&nbsp;A matéria prima comercializada em nossa empresa tem origem de reflorestamentos, tendo assim sua origem comprovada sem agredir ao meio ambiente.</p>
                            <p className='texto-inicio'>&nbsp;&nbsp;Trabalhamos também com a madeira de eucalipto para uso da construção civil, sendo destinada para fabricação de assoalho, deck, forro e lambril divisória.</p>
                        </div>
                        <div className='col-sm-12 text-right p-2 bd-highlight'>
                        </div>
                        
                    </div>                    
                </div>
            </div>
        </div>
    </div>

    )
}

export default Inicio