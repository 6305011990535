import React, { Component } from 'react';
import { BrowserRouter, Route } from 'react-router-dom';

import Rodape from './componentes/Rodape';
import Cabecalho from './componentes/Cabecalho';
import Inicio from './componentes/Inicio';
import Empresa from './componentes/Empresa';
import Portfolio from './componentes/Portfolio';
import Precos from './componentes/Precos';
import Contatos from './componentes/Contatos';

import Admin from './admin/Admin';
import Login from './admin/Login';

class App extends Component {
  render() {
    return (
      <BrowserRouter>
        <div className="App">
          <Cabecalho />          
          <Route path='/' exact component={Inicio} />
          <Route path='/Empresa' component={Empresa} />
          <Route path='/Portfolio' component={Portfolio} />
          <Route path='/Precos' component={Precos} />
          <Route path='/Contatos' component={Contatos} />
          <Route path='/admin' component={Admin} />
          <Route path='/login' component={Login} />
          <Rodape />
        </div></BrowserRouter>


    );
  }

}

export default App;
