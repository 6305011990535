import React, {Component} from 'react'
import {Route, Redirect} from 'react-router-dom'
import AdminMenu from './AdminMenu'
import AdminPortfolio from './AdminPortfolio'
import {auth} from './../firebase-config'

class Admin extends Component{
    constructor (props) {
        super (props)

        this.state ={
            estaLogando: true,
            estaAutenticado: false,
            user: null
        }
    }
    componentDidMount(){
        auth.onAuthStateChanged(user =>{
            this.setState({
                estaLogando: false,
                estaAutenticado: !!user,
                user
            })
        })
    }
    render(){
        if(this.state.estaLogando){
            return(
                <div style={{padding: 120+'px'}} className="container">
                    <p>Aguarde... <span className="glyphicon glyphicon-refresh"></span></p>    
                </div>
            ) 
        }
        if(!this.state.estaAutenticado){
            return <Redirect to='/login' />
        }

        return(
            <div  style={{padding: 70 +'px'}} >
                <div className='container' >
                    <h2>Área Administrativa</h2>
                    <Route path={"/"} component={AdminMenu} />
                    <Route path={`${this.props.match.url}/portfolio`} component={AdminPortfolio} />
                </div>
            </div>
            
        )
    }
}

export default Admin